require('typeface-clear-sans');
const Parser = require('rss-parser');
const Amplitude = require('amplitudejs');

let parser = new Parser();


function createCommentThread({ id, title }){
  try {
    const commentContainer = document.getElementById('isso-thread');
    commentContainer.setAttribute('id', 'isso-thread');
    commentContainer.setAttribute('data-title', title);
    commentContainer.setAttribute('data-isso-id', id);

    document.querySelectorAll('body > .container')[0].appendChild(commentContainer);
    window.Isso.fetchComments();
  } catch(e) {
    console.warn('failed to load comment feed')
  }
}

// async function getFeed(){
//   let feed = await parser.parseURL('https://feeds.buzzsprout.com/1263185.rss');
// 
//   const mostRecent = feed.items[0];
//   return { ...mostRecent, feedTitle: feed.title, image: feed.image.url }
// }

async function getFeed(){
  const url = 'https://api.sheety.co/2089e5bdad30eaab41da060ae0443cc5/readingsAndPrayers/combined';
  const { combined } = await fetch(url).then((response) => response.json())
  const t = new Date();
  const dateString = `${t.getMonth() + 1}/${t.getDate()}/${t.getFullYear()}`;
  const today = combined.find(({ date }) => date === dateString)
  console.log(today);
  return { ...today, scripture: today.readings, title: t.toDateString() };
}

function buildEsvIframe(verse){
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.esv.org/audio-player/${verse}/`;
  iframe.style = "border: 0; width: 100%; height: 109px;"

  return iframe;
}

const SPOTIFY_REGEX = /\/(?<type>(?:track)|(?:playlist))\/(?<id>\w+)?/gm;

function appendSpotify({ closingSong }){
  const match = SPOTIFY_REGEX.exec(closingSong);
  const { type, id } = match.groups;

  const iframe = document.createElement('iframe');
  console.log(type, id);
  iframe.src = `https://open.spotify.com/embed/${type}/${id}?theme=0`;
  iframe.width = '100%';
  iframe.height = '380';
  iframe.frameBorder = '0';
  iframe.allowtransparency = 'true';
  iframe.allow = 'encrypted-media';

  document.getElementById("container").append(iframe);
}

async function updateScripture({ scripture }){
  const verseAtoms = scripture.split("\n")[0].split('|').map((atom) => {
    return atom.trim().replace('.', ':',).replace(' ', '+').replace(';', ' ')
  })
  // const searchString = verseAtoms.join(';')
  const html = await Promise.all(verseAtoms.map(async(atom) => {
    const verse = await fetch(
    `https://api.esv.org/v3/passage/html/?q=${atom}&include-css-link=true&include-audio-link=false&include-footnotes=false`,
        { headers: { Authorization: 'Token 32e55b74fdded7351187b0d492b51469274373af' }}
      ).then(res => res.json())
     return verse.passages.map(html => `<div data-scripture="${atom}" class="scripture-block">${html}</div>`).join('');
  }));

  document.getElementById('esv-scripture').innerHTML = html.join('\n\n');
  // console.log(document.getElementById('esv-scripture').innerHTML)
  document.querySelectorAll('.scripture-block').forEach(el => {
    const container = document.createElement('div');
    container.classList.add('closed', 'scripture-container')
    el.querySelectorAll(':scope > *:not(.extra_text)').forEach(sel => {
      // console.log(sel);
      container.appendChild(sel);
      // sel.remove();
    })
    el.appendChild(container);
  })
  document.querySelectorAll('.extra_text').forEach(textEl => {

    textEl.addEventListener('click', () => {
      const parent = textEl.parentNode;
      parent.classList.toggle('open');
      const el = textEl.parentNode.querySelector('.scripture-container');
      if(parent.classList.contains('open')) {
        el.style.height = el.scrollHeight + 'px';
      } else {
        el.style.height = 0;
      }
    })
  })

  document.querySelectorAll('.scripture-container').forEach(el => {
    const reference = el.parentNode.getAttribute("data-scripture")
    el.prepend(buildEsvIframe(reference))
  })

  document.querySelectorAll(".copyright").forEach(el => el.previousSibling.innerText = '')
}

async function setupPlayer({ url, name, image, title }){
  Amplitude.init({
    "bindings": {
      37: 'prev',
      39: 'next',
    },
    "songs": [
      {
        "name": name,
        "artist": "New City",
        "album": title,
        "url": url,
        "cover_art_url": image
      }
    ]
  });

  // window.onkeydown = function(e) {
  //     return !(e.keyCode == 32);
  // };

  /*
    Handles a click on the song played progress bar.
  */
  document.getElementById('song-played-progress').addEventListener('click', function( e ){
    var offset = this.getBoundingClientRect();
    var x = e.pageX - offset.left;

    Amplitude.setSongPlayedPercentage( ( parseFloat( x ) / parseFloat( this.offsetWidth) ) * 100 );
  });
}

async function updateTitle({ title }){
  // document.getElementById('title').innerText = title;
}

(async () => {

  const mostRecent = await getFeed();
  // setupPlayer({ url: mostRecent.enclosure.url, name: mostRecent.title, image: mostRecent.image, title: mostRecent.feedTitle });
  // createCommentThread({ title: mostRecent.title, id: mostRecent.title.toLowerCase().replace(/[^\w\d]/g, '-')})
  updateScripture({ scripture: mostRecent.scripture });
  updateTitle({ title: mostRecent.title });
  try {
    appendSpotify({ closingSong: 'https://open.spotify.com/playlist/1MLbjvaOGDmddyLv8uYdNO' });
  } catch (e){
    console.log(e);
  }

})();
